import { Button } from "@components/button";

export default function NotFound() {
  return (
    <div className="h-screen bg-neutral-160 w-full flex items-center justify-center">
      <div className="flex flex-col items-center" items-center>
        <h2 className="text-3xl mb-4 text-center">
          Oops. This page was not found
        </h2>
        <a href="https://staking.jellybabiesnft.com/">
          <Button size="large">Go back home</Button>
        </a>
      </div>
    </div>
  );
}
